import { createGlobalStyle } from "styled-components";
import px2vw from "../utils/px2vw";
import { colorPalette } from "./colorSchema";

export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline:none;
    min-width:40px;
    box-sizing: border-box;
  }
  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    font-family: sans-serif;
  }
  :root {
    color: ${colorPalette.white};
    /* h2{
      font-size: ${px2vw(75)};
    } */
    /* h6{
      font-size: ${px2vw(55)};
    } */
  
      @media (max-width: 768px) {

        /* font-size: ${px2vw(36)}; */
        /* h2{

          font-size: ${px2vw(24)};
        } */
        /* h6{
          font-size: ${px2vw(24)};
        } */

      }
      @media (min-width: 1024px) {
        font-size: ${px2vw(16)};
        /* h2{
          font-size: ${px2vw(24)};
        }
        h6{
          font-size: ${px2vw(18)};
        } */
      }
    }
`;

export default Global;