import styled from 'styled-components';
import { colorPalette } from '../styles/colorSchema';

export const Container = styled.div`

  h1{
    color: ${colorPalette.primary};
  }
  div{
    color: ${colorPalette.black};
  }
`;
export const Wrapper = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-left:auto;
margin-right:auto;
  padding:10px 25px 5px 25px;
  max-width:760px;
`;
