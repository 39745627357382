import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/header';
import Global from '../styles/global';
import { Container, Wrapper } from './styled';

export default function BlogPost({ data }) {
  const post = data.markdownRemark;

  return (
    <Container>
      <Global />
      <Header />
      <Wrapper>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </Wrapper>
    </Container>
  )
}
export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields:{slug:{eq:$slug}}) {
      frontmatter {
        title
      }
      html
    }
  }
`;